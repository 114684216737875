export default {
    "AdditionalUsers.four_plus": "{first}, {second} e mais {number}",
    "AdditionalUsers.plus_more": "+ {number} outros",
    "AdditionalUsers.three": "{first}, {second} e {third}",
    "AdditionalUsers.two": "{first} e {second}",
    "AppointmentCancelled.email_message": "Enviamos um e-mail para você confirmando o cancelamento.",
    "AppointmentCancelled.this_title": "Este compromisso foi cancelado",
    "AppointmentCancelled.your_title": "Seu compromisso foi cancelado",
    "AppointmentDetails.appointment_details": "Detalhes do compromisso",
    "AppointmentDetails.appointment_duration": "{start} - {end} ({timezone})",
    "AppointmentDetails.appointment_duration_without_end": "{start} ({timezone})",
    "AppointmentDetails.attendee": "Participante",
    "AppointmentDetails.attendees": "Participantes",
    "AppointmentDetails.confirmation_number": "Número de confirmação",
    "AppointmentDetails.date_time": "Data e hora",
    "AppointmentDetails.join_by_phone": "Participar por telefone: <a>{number}<\/a>",
    "AppointmentDetails.join_call": "Entrar na chamada",
    "AppointmentDetails.join_the_call": "Entrar com o link fornecido",
    "AppointmentDetails.join_with_this_link": "Entrar usando este link",
    "AppointmentDetails.location": "Local",
    "AppointmentDetails.phone_call": "Telefonema",
    "AppointmentDetails.phone_number_ending_in": "Número de telefone com final {number}",
    "AppointmentDetails.pin": "PIN: {pin}",
    "AppointmentDetails.service": "Serviço",
    "AppointmentDetails.user": "Equipe",
    "AppointmentDetails.video_call": "Chamada de vídeo",
    "AppointmentDetails.we_will_call": "Ligaremos para você no número fornecido",
    "AppointmentDetails.we_will_call_you": "Ligaremos para você no número fornecido",
    "AppointmentDetails.we_will_send_video_info": "Enviaremos a você informações sobre como participar da reunião",
    "AppointmentSummary.cancel": "Cancelar",
    "AppointmentSummary.default_error_title": "Ocorreu um erro",
    "AppointmentSummary.location_button": "Ver locais",
    "AppointmentSummary.location_unavailable_description": "Este local não está mais disponível. Você precisará selecionar outro local para poder agendar um compromisso.",
    "AppointmentSummary.location_unavailable_title": "O local não está mais disponível",
    "AppointmentSummary.modal_title": "Título",
    "AppointmentSummary.service_button": "Ver serviços",
    "AppointmentSummary.service_unavailable_description": "Este serviço não está mais disponível. Veja se outros serviços atendem às suas necessidades ou entre em contato conosco pelo telefone {phone} para obter ajuda.",
    "AppointmentSummary.service_unavailable_title": "O serviço não está mais disponível",
    "AppointmentSummary.times_button": "Encontrar um horário",
    "AppointmentSummary.times_unavailable_description": "{time} não está mais disponível em {date}. Você precisará selecionar outro horário para poder agendar um compromisso.",
    "AppointmentSummary.times_unavailable_title": "O horário não está mais disponível",
    "AppointmentSummaryExpansionPanel.title": "Resumo do compromisso",
    "AttendeeListItem.delete": "Remover participante adicional",
    "AttendeeListItem.deleted": "{name} removido",
    "AttendeeListItem.edit": "Atualizar participante adicional",
    "BackButton.back": "Voltar",
    "BackButton.back_results": "Voltar para resultados",
    "BookingValidationErrorModal.default_error_title": "Ocorreu um erro",
    "BookingValidationErrorModal.location_button": "Ver locais",
    "BookingValidationErrorModal.location_unavailable_description": "Este local não está mais disponível. Você precisará selecionar outro local para poder agendar um compromisso.",
    "BookingValidationErrorModal.location_unavailable_title": "O local não está mais disponível",
    "BookingValidationErrorModal.modal_title": "Título",
    "BookingValidationErrorModal.service_button": "Ver serviços",
    "BookingValidationErrorModal.service_unavailable_description": "Este serviço não está mais disponível. Veja se outros serviços atendem às suas necessidades ou entre em contato conosco pelo telefone {phone} para obter ajuda.",
    "BookingValidationErrorModal.service_unavailable_title": "O serviço não está mais disponível",
    "BookingValidationErrorModal.times_button": "Encontrar um horário",
    "BookingValidationErrorModal.times_unavailable_description": "{time} não está mais disponível em {date}. Você precisará selecionar outro horário para poder agendar um compromisso.",
    "BookingValidationErrorModal.times_unavailable_title": "O horário não está mais disponível",
    "CancelModal.additional_details": "Detalhes adicionais",
    "CancelModal.appointment_date": "{date} às {time}",
    "CancelModal.cancel_appointment": "Cancelar compromisso",
    "CancelModal.keep": "Manter",
    "CancelModal.message": "O cancelamento do seu compromisso agendado em {date} não pode ser desfeito.",
    "CancelModal.title": "Cancelar compromisso",
    "CancelModal.why_cancel_appointment": "Por que este compromisso está sendo cancelado?",
    "ChangesToYourAppointment.cancellation_no_longer_available": "Este compromisso não pode mais ser cancelado.",
    "ChangesToYourAppointment.cancellation_policy_header": "Política de cancelamento",
    "ChangesToYourAppointment.cannot_cancel": "Este compromisso não pode ser cancelado.",
    "ChangesToYourAppointment.cannot_reschedule": "Este compromisso não pode ser reagendado.",
    "ChangesToYourAppointment.loading": "Carregando...",
    "ChangesToYourAppointment.meeting_method_subtitle": "Se quiser alterar como você comparecerá ao seu compromisso, atualize o método de reunião.",
    "ChangesToYourAppointment.meeting_method_subtitle_limited": "Se quiser alterar como comparecerá ao seu compromisso, você poderá fazer isso até {duration} antes do horário agendado.",
    "ChangesToYourAppointment.meeting_method_subtitle_locked": "O método de reunião não pode ser alterado",
    "ChangesToYourAppointment.no_meeting_method_options": "Não há outras opções disponíveis",
    "ChangesToYourAppointment.reschedule_appointment": "Reagendar compromisso",
    "ChangesToYourAppointment.reschedule_header": "Reagendar",
    "ChangesToYourAppointment.reschedule_no_longer_available": "Este compromisso não pode mais ser reagendado.",
    "ChangesToYourAppointment.reschedule_policy_details": "Se você precisar reagendar seu compromisso, poderá fazer isso até {duration} antes do horário agendado.",
    "ChangesToYourAppointment.reschedule_policy_details_no_limit": "Se você precisar reagendar seu compromisso, poderá fazer isso antes do horário agendado.",
    "ChangesToYourAppointment.title": "Alterações em seu compromisso",
    "ChangesToYourAppointment.update_meeting_method": "Atualizar método de reunião",
    "ChangesToYourAppointment.update_meeting_method_not_available": "O método de reunião deste compromisso não pode mais ser alterado.",
    "CheckIn.check_in": "Check-in",
    "CheckIn.check_in.aria_label": "Verifique em seu compromisso",
    "CheckIn.confirmation.subtitle": "Estaremos com você em breve.",
    "CheckIn.confirmation.subtitle_notifiable": "Estaremos com você em breve. Avisaremos quando chegar a sua vez.",
    "CheckIn.confirmation.title": "Você fez check-in!",
    "CheckIn.email": "Endereço de email",
    "CheckIn.fetch_failed": "Lamentamos, mas ocorreu um erro ao procurar a sua consulta. Atualize a página e tente novamente.",
    "CheckIn.join_line": "JUNTE-SE À LINHA",
    "CheckIn.join_line.aria_label": "Clique aqui para entrar na fila para ser atendido ao invés de agendar um horário",
    "CheckIn.join_line.title": "Não tem hora marcada?",
    "CheckIn.landing_page.title": "Procure seu compromisso",
    "CheckIn.next": "Próximo",
    "CheckIn.next.aria_label": "Procure seu compromisso",
    "CheckIn.not_found.email": "Lamentamos, mas não conseguimos encontrar um compromisso futuro que corresponda ao endereço de e-mail que você inseriu. Observe que os compromissos só podem ser verificados dentro de 30 minutos do horário de início agendado.",
    "CheckIn.not_found.phone": "Lamentamos, mas não conseguimos encontrar um compromisso futuro que corresponda ao número de telefone que você inseriu. Observe que os compromissos só podem ser verificados dentro de 30 minutos do horário de início agendado.",
    "CheckIn.phone": "Número de telefone",
    "CheckIn.preference": "Usando sua {preference} para procurar seu compromisso",
    "CheckIn.subtitle": "Use seu endereço de e-mail ou número de telefone para procurar seu compromisso",
    "CheckIn.subtitle_email_only": "Use seu endereço de e-mail para procurar seu compromisso",
    "CheckIn.summary.fetch_failed": "Lamentamos, mas ocorreu um erro ao verificar a sua marcação. Atualize a página e tente novamente.",
    "CheckIn.summary.sms_updates": "Receba atualizações por mensagem de texto",
    "CheckIn.summary.sms_updates.dialog": "Receber atualizações por mensagem de texto?",
    "CheckIn.summary.sms_updates.dialog.accept": "Sim, enviar atualizações por SMS",
    "CheckIn.summary.sms_updates.dialog.deny": "Não, fazer check-in sem obter atualizações",
    "CheckIn.summary.sms_updates.message": "Enviaremos atualizações sobre quando for sua vez de {phone}",
    "CheckIn.summary.subtitle": "Confirme os detalhes do seu agendamento abaixo e faça o check-in",
    "CheckIn.summary.title": "Confirmar e fazer check-in",
    "CheckIn.title": "Procure seu compromisso",
    "CircularProgress.loading": "Carregando",
    "Common.appointment_details": "Detalhes do compromisso",
    "CookieNotice.accept": "Aceito",
    "CookieNotice.closed": "Isenção de responsabilidade de cookies fechada.",
    "CookieNotice.ignore": "Ignorar",
    "CookieNotice.learn_more": "Saiba mais",
    "CookieNotice.message": "Usamos cookies para garantir que você tenha a melhor experiência possível em nosso site.",
    "CookieNotice.title": "Isenção de responsabilidade de cookies",
    "CountrySelectInput.label": "País",
    "CountrySelectInput.most_popular": "Mais populares",
    "CountryTimezoneModal.description": "Todos os horários serão exibidos de acordo com o fuso horário selecionado abaixo.",
    "CountryTimezoneModal.title": "Selecionar fuso horário",
    "DateTime.locale_badge_label": "{name} fala {language}",
    "DateTime.locale_badge_tooltip": "Fala {language}",
    "DateTime.mobile_header": "Para quando você deseja agendar seu compromisso?",
    "DateTime.no_language_preference": " (sem {locale})",
    "DateTime.no_times_available_on_date": "Não há horários disponíveis",
    "DateTime.select_time": "Selecionar hora",
    "DateTime.select_user": "Selecionar um membro da equipe",
    "DateTime.showing_times_for": "Mostrando horários para",
    "DateTime.times_available_for_people_speaking": "Horários disponíveis para {locale} em {date}",
    "DateTime.times_available_on_date": "Horários disponíveis em {date}",
    "DateTime.timezone_header": "Os horários são mostrados em {timezone}",
    "DateTime.user_mobile_header": "Quando você gostaria de se reunir com {name}?",
    "DateTime.user_schedule": "Agendar um horário com {name}",
    "DateTime.who_speaks": " que fala {locale}",
    "Dates.all_day_date": "{date}, o dia todo",
    "Dates.all_day_today": "Hoje, o dia todo",
    "Dates.plural.day": "{count, plural, one {dia} other {dias}}",
    "Dates.plural.month": "{count, plural, one {mês} other {meses}}",
    "Dates.plural.week": "{count, plural, one {semana} other {semanas}}",
    "Dates.plural.year": "{count, plural, one { ano } other { anos }}",
    "Dates.range_today": "Hoje, de {start} a {end}",
    "Dates.range_today_with_timezone": "Hoje, de {start} a {end} {timezone}",
    "Dates.today_with_timezone": "Hoje, {date} {timezone}",
    "DesktopUsers.locale_badge_tooltip": "Fala {language}",
    "Details.form_header": "Insira seus detalhes",
    "DetailsForm.add": "Adicionar",
    "DetailsForm.add_attendee": "+ Adicionar participante",
    "DetailsForm.add_attendee_title": "Adicionar participante",
    "DetailsForm.additional_attendees": "Participantes adicionais",
    "DetailsForm.additional_attendees_limit": "(máx. {limit})",
    "DetailsForm.address": "Endereço",
    "DetailsForm.agree_tos": "Ao agendar um compromisso, você concorda com os {terms} e o {privacy} da Coconut Software.",
    "DetailsForm.agree_tos_with_privacy": "Ao agendar um compromisso, você concorda com os {terms} e o {privacy} da Coconut Software e com o {vendorPrivacy} da {vendor}.",
    "DetailsForm.agree_tos_with_tos": "Ao agendar um compromisso, você concorda com os {terms} e o {privacy} da Coconut Software e com os {vendorTerms} da {vendor}.",
    "DetailsForm.agree_tos_with_vendor": "Ao agendar um compromisso, você concorda com os {terms} e o {privacy} da Coconut Software e com os {vendorTerms} e o {vendorPrivacy} da {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy": "Ao agendar um compromisso, você concorda com os {vendorTerms} e o {vendorPrivacy} da {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_policy": "Ao agendar um compromisso, você concorda com o {vendorPrivacy} da {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_tos": "Ao agendar um compromisso, você concorda com os {vendorTerms} da {vendor}.",
    "DetailsForm.appointment_policy_action": "marcar uma consulta",
    "DetailsForm.cell_phone": "Número de celular",
    "DetailsForm.cell_phone_context": "Ligaremos para você neste número",
    "DetailsForm.city": "Cidade",
    "DetailsForm.coconut_privacy_url": "https:\/\/www.coconutsoftware.com\/privacy-policy\/",
    "DetailsForm.coconut_terms_url": "https:\/\/www.coconutsoftware.com\/terms-of-use\/",
    "DetailsForm.country": "País",
    "DetailsForm.duplicate_attendee": "Já existe um participante com este nome e e-mail",
    "DetailsForm.edit": "Salvar",
    "DetailsForm.edit_attendee_title": "Editar participante",
    "DetailsForm.email": "E-mail",
    "DetailsForm.explicit_compliance_agreement_label": "Aceito",
    "DetailsForm.first_name": "Nome",
    "DetailsForm.home_phone": "Número de telefone residencial",
    "DetailsForm.last_name": "Sobrenome",
    "DetailsForm.notes": "Detalhes do compromisso",
    "DetailsForm.postal_code": "CEP\/Código postal",
    "DetailsForm.privacy": "aviso de privacidade",
    "DetailsForm.province": "Província",
    "DetailsForm.receive_sms": "Receber notificações de compromisso por mensagem de texto",
    "DetailsForm.receive_sms_required": "Usaremos essas informações para enviar atualizações importantes sobre seu compromisso para você",
    "DetailsForm.select_country": "Selecione um país",
    "DetailsForm.select_province": "Selecione uma província",
    "DetailsForm.select_state": "Selecione um estado",
    "DetailsForm.sms_error_title": "Opte novamente por receber mensagens de texto.",
    "DetailsForm.state": "Estado",
    "DetailsForm.submit": "Continuar",
    "DetailsForm.terms_of_use": "termos de uso",
    "DetailsForm.walkin_policy_action": "juntando-se à linha",
    "DetailsForm.work_phone": "Número de telefone comercial",
    "Dialog.clear": "Apagar",
    "Dialog.confirm": "Confirmar",
    "Dropzone.delete": "Excluir",
    "Dropzone.instructions": "Arraste arquivos aqui ou <span>procure-os para carregar<\/span>",
    "Duration.days": "{duration, plural, one {# dia} other {# dias}}",
    "Duration.hours": "{duration, plural, one {# hora} other {# horas}}",
    "Duration.minutes": "{duration, plural, one {# minuto} other {# minutos}}",
    "Duration.weeks": "{duration, plural, one {# semana} other {# semanas}}",
    "EmptyState.contact_vendor": "Tente novamente ou entre em contato conosco",
    "FastRoute.book_anyway": "Agendar um compromisso em vez disso",
    "FastRoute.skip_the_wait": "Pular a espera",
    "FastRoute.title": "Nenhum compromisso é necessário",
    "Feedback.already_submitted": "Feedback já enviado, obrigado!",
    "Feedback.appointment_review_subtitle": "Por favor, diga-nos como você se sentiu sobre a sua nomeação.",
    "Feedback.appointment_review_title": "Feedback de agendamento",
    "Feedback.close": "Fechar seção de feedback",
    "Feedback.error": "Forneça uma classificação antes de enviar",
    "Feedback.experience": "Sua experiência",
    "Feedback.experience_error_message": "O feedback é um campo obrigatório. Certifique-se de que o comprimento seja maior que 144 caracteres",
    "Feedback.experience_required": "Sua experiência (obrigatório)",
    "Feedback.google_description": "Valorizamos muito seu feedback e acreditamos que nossos outros clientes se beneficiarão disso. Se tiver um tempinho para nos contar sua experiência conosco usando uma avaliação do Google, clique no botão abaixo e redirecionaremos você.",
    "Feedback.google_submit": "Enviar avaliação do Google",
    "Feedback.neutral": "Neutro",
    "Feedback.new_title": "Feedback",
    "Feedback.rating": "Classificação (obrigatório)",
    "Feedback.response_prompt": "Conte para nós como sua experiência poderia ter sido melhor",
    "Feedback.send": "Enviar feedback",
    "Feedback.sending": "Enviando feedback",
    "Feedback.staff_review_subtitle": "Por favor, conte-nos como nosso membro da equipe se saiu hoje.",
    "Feedback.staff_review_title": "Feedback da equipe",
    "Feedback.subtitle": "Seu feedback é anônimo. Usaremos essas informações para melhorar sua experiência de agendamento.",
    "Feedback.success": "Feedback enviado. Nossos agradecimentos!",
    "Feedback.thank_you": "Agradecemos o seu feedback!",
    "Feedback.title": "Como foi sua experiência ao agendar este compromisso?",
    "Feedback.very_dissatisfied": "Muito insatisfeito",
    "Feedback.very_satisfied": "Muito satisfeito",
    "FetchSlots.error": "Algo deu errado, tente novamente mais tarde",
    "FindAvailableDate.earliest_available": "Mais antigo disponível",
    "FindAvailableDate.finding_first_time": "Encontrando o primeiro horário disponível",
    "FindAvailableDate.finding_next_time": "Encontrando o próximo horário disponível",
    "FindAvailableDate.finding_time": "Encontrando um horário disponível",
    "FindAvailableDate.first_available_date": "O mais breve possível",
    "FindAvailableDate.next_available": "Próximo disponível",
    "FindAvailableDate.next_available_date": "Próxima data disponível",
    "FindAvailableDate.tooltip_error": "Erro ao buscar a data.",
    "FindAvailableDate.tooltip_fetching": "Buscando data...",
    "Form.field_error.cell_phone_characters": "Insira um número de telefone celular válido.",
    "Form.field_error.cell_phone_length": "Por favor, insira um número de telefone celular válido entre 9 e 20 caracteres.",
    "Form.field_error.email_structure": "Por favor insira um endereço de e-mail válido.",
    "Form.field_error_max_length": "Este campo não pode exceder {max} caracteres.",
    "Form.field_error_min_length": "Este campo não pode ter menos de {min} caracteres.",
    "Form.field_required": "Este campo é obrigatório.",
    "Form.optional": "(opcional)",
    "GenericError.subtitle": "A página que você está procurando não foi encontrada",
    "GenericError.title": "Opa, algo deu errado",
    "Input.additional_input": "Mensagem adicional",
    "Invitations.verification_failed": "O endereço de e-mail que você forneceu não corresponde ao que está em nossos registros.",
    "Label.phone_number": "Número de telefone",
    "LandingChoices.book_appointment_subtitle": "Selecione uma data e hora específica para contatar alguém",
    "LandingChoices.book_appointment_title": "Agendar um compromisso",
    "LandingChoices.callback_subtitle": "Contatar alguém hoje o mais rápido possível",
    "LandingChoices.callback_title": "Entrar na fila",
    "LandingChoices.check_in_subtitle": "Se você estiver aqui para um compromisso pré-agendado",
    "LandingChoices.check_in_title": "Check-in",
    "LandingChoices.choose_another_location_subtitle": "Clique aqui para procurar outro local",
    "LandingChoices.prefill_title": "Bem-vindo(a) {name}! O que você gostaria de{space}fazer?",
    "LandingChoices.prefill_title.service": "Bem-vindo(a) {name}! Como você gostaria de agendar seu compromisso de {service}?",
    "LandingChoices.private_location": "Este local é privado",
    "LandingChoices.title": "O que você gostaria de{space}fazer?",
    "LandingChoices.title.service": "Como você gostaria de agendar seu compromisso de {service}?",
    "LandingChoices.virtual_location": "Localização virtual",
    "LandingChoices.welcome_to": "Bem-vindo(a) a",
    "Languages.chinese": "chinês",
    "Languages.english": "Inglês",
    "Languages.french": "Francês",
    "Languages.korean": "Coreano",
    "Languages.language": "Idioma",
    "Languages.polish": "Polonês",
    "Languages.portuguese": "Português",
    "Languages.russian": "Russo",
    "Languages.spanish": "Espanhol",
    "LobbyBanner.button": "Entrar na fila",
    "LobbyBanner.button_description": "Clique aqui para entrar na fila para ser atendido ao invés de agendar um horário",
    "LobbyBanner.phone_button": "Solicitar um retorno de chamada",
    "LobbyBanner.phone_button_description": "Clique aqui para agendar um retorno de chamada em vez de agendar uma consulta",
    "LobbyBanner.title": "Nenhum compromisso é necessário.",
    "LobbyBanner.wait_time": "Tempo de espera estimado: {time} minutos",
    "Location.previously_booked": "Agendado anteriormente",
    "Location.search_area": "Pesquisar esta área",
    "Location.select_location": "Selecionar um local",
    "Location.select_location_prefill": "{name}, selecione um local",
    "LocationDetails.continue": "Continuar",
    "LocationDetails.location_details": "Detalhes do local",
    "LocationDetails.see_all_locations": "Ver todos os locais",
    "LocationDetails.select_location": "Selecionar este local",
    "LocationDirectionsButton.text": "Como chegar",
    "LocationHours.closed": "Fechado",
    "LocationHours.title": "Horário de funcionamento",
    "LocationIdentifier.empty_error": "Insira o número do local",
    "LocationIdentifier.help_text": "Comece inserindo o número do local que você vê na placa",
    "LocationIdentifier.location_number": "Número do local",
    "LocationIdentifier.not_found_error": "Isso não coincide com nenhum de nossos locais",
    "LocationIdentifier.title": "Bem-vindo(a)!",
    "LocationList.empty_state_message": "Nenhum resultado encontrado",
    "LocationList.empty_state_secondary": "Tente ampliar sua pesquisa",
    "LocationList.finding_locations": "Estamos procurando locais próximos a você",
    "LocationList.near_me": "Locais próximos a mim",
    "LocationList.no_locations": "Nenhum local encontrado",
    "LocationList.title": "Selecionar um local",
    "LocationList.toggle_list": "Mostrar lista",
    "LocationList.toggle_map": "Mostrar mapa",
    "LocationPhoneCallButton.text": "Ligar para {number}",
    "LocationStepTitle.preferred_location": "Qual é o seu local preferido?",
    "LocationStepTitle.preferred_location_helper": "Faremos a correspondência entre você e a equipe de acordo com o local escolhido.",
    "LoginWithGoogle.showing_times_that_match": "Mostrando horários que coincidem com a sua disponibilidade",
    "LoginWithGoogle.sign_in": "Entre com o Google",
    "LoginWithGoogle.sign_in_details": "para mostrar os horários que coincidem com a sua disponibilidade",
    "Manage.appointment_details": "Detalhes do compromisso",
    "Manage.book_another": "Agendar outro compromisso",
    "Manage.can_never_cancel": "O gerenciamento online não está disponível. Entre em contato conosco para fazer alterações.",
    "Manage.cancel": "Cancelar compromisso",
    "Manage.cancellation_policy": "Política de cancelamento",
    "Manage.cancellation_policy_details": "Se você não puder comparecer ao seu compromisso, cancele-o até {duration} antes do horário agendado.",
    "Manage.cancellation_policy_details_no_limit": "Se você não puder comparecer ao seu compromisso, cancele-o antes do horário agendado.",
    "Manage.cancelled": "O compromisso foi cancelado",
    "Manage.cancelled_successfully": "O compromisso foi cancelado",
    "Manage.cannot_cancel_anymore": "O gerenciamento online não está disponível no momento. Entre em contato conosco para fazer alterações.",
    "Manage.confirmation": "Confirmação",
    "Manage.confirmation_email": "Enviamos um e-mail para você com detalhes do compromisso, com informações adicionais sobre ele.",
    "Manage.details": "Detalhes",
    "Manage.in_the_past": "O compromisso está no passado",
    "Manage.reschedule": "Reagendar",
    "Manage.restart": "Reiniciar pela página inicial",
    "Manage.successfully_booked": "Compromisso agendado",
    "Manage.updated_successfully": "O compromisso foi atualizado",
    "ManageAppointmentDetails.details_below": "Os detalhes do seu compromisso estão abaixo. Enviamos uma cópia por e-mail para você.",
    "ManageAppointmentDetails.done_title": "Pronto!",
    "ManageAppointmentDetails.successfully_booked": "Seu compromisso foi agendado.",
    "ManageAppointmentDetails.successfully_rescheduled": "Seu compromisso foi reagendado.",
    "ManageAppointmentDetails.your_appointment": "Seu compromisso",
    "MeetingMethodForm.appointment_date": "{date} às {time}",
    "MeetingMethodForm.keep": "Manter",
    "MeetingMethodForm.message.in_person": "Atualize o método de reunião para o compromisso que está agendado em {date} para presencial.",
    "MeetingMethodForm.message.off_site": "Atualize o método de reunião para o compromisso que está agendado em {date} para onde você quiser.",
    "MeetingMethodForm.message.phone_call": "Atualize o método de reunião para o compromisso que está agendado em {date} para telefonema.",
    "MeetingMethodForm.message.video_call": "Atualize o método de reunião para o compromisso que está agendado em {date} para chamada de vídeo.",
    "MeetingMethodForm.new_method_label": "Como você comparecerá ao compromisso?",
    "MeetingMethodForm.title": "Atualizar método de reunião",
    "MeetingMethodForm.update_method": "Atualizar método de reunião",
    "MeetingMethodInfoBanner.only_phone_available": "Esta reunião está disponível somente por telefonema",
    "MeetingMethodInfoBanner.only_video_available": "Esta reunião está disponível somente por chamada de vídeo",
    "MeetingMethods.at_location_primary": "Presencial",
    "MeetingMethods.at_location_secondary": "Escolha um de nossos locais",
    "MeetingMethods.header": "Como você comparecerá ao compromisso?",
    "MeetingMethods.off_site_primary": "Local externo",
    "MeetingMethods.off_site_secondary": "Nós nos encontraremos com você",
    "MeetingMethods.phone_call_primary": "Telefone",
    "MeetingMethods.phone_call_secondary": "Nós ligaremos para você",
    "MeetingMethods.video_call_primary": "Vídeo",
    "MeetingMethods.video_call_secondary": "Nós enviaremos um link para você",
    "MonthlyDatePicker.next_month": "Próximo mês",
    "MonthlyDatePicker.previous_month": "Mês anterior",
    "Navigation.aria_label.back": "Voltar para a etapa anterior",
    "Navigation.aria_label.call": "Ligar para o local",
    "Navigation.aria_label.card": "Selecionar {navItem}",
    "Navigation.aria_label.date": "Selecionar {date}",
    "Navigation.aria_label.directions": "Ver como chegar",
    "Navigation.aria_label.language": "Equipe que fala {language}",
    "Navigation.aria_label.language_specific": "uma equipe específica",
    "NoLocationPreferenceButton.skip": "Pular",
    "NoLocationPreferenceButton.title": "Não tenho uma preferência",
    "NoTimesAvailable.no_times_in_month": "Nenhum horário em {month}",
    "NoTimesAvailable.no_times_in_week": "Nenhum horário esta semana",
    "NoTimesAvailable.view_next_month": "Visualizar próximo mês",
    "NoTimesAvailable.view_next_week": "Visualizar próxima semana",
    "NotAvailable.subtitle": "Não estamos aceitando compromissos no momento",
    "NotAvailable.title": "Não disponível",
    "OtherLocationsTimeChunks.available_times_title": "Horários disponíveis em outros locais",
    "OtherLocationsTimeChunks.no_available_times": "Não há horários disponíveis em outros locais",
    "OtherLocationsTimeChunks.view_available_times": "Visualizar horários disponíveis em outros locais ",
    "OtherLocationsTimeChunks.view_more": "Visualizar mais",
    "Pages.country": "País",
    "Pages.staff_preference": "Preferência de equipe",
    "Pages.supported_language_preference": "Idiomas",
    "Pages.timezones": "Fusos horários",
    "PositionErrorAlert.denied": "Não foi possível obter sua localização porque não temos sua permissão. Confira as configurações do seu navegador para este site.",
    "PositionErrorAlert.retry": "Repetir",
    "PositionErrorAlert.unable": "Não foi possível obter sua localização.",
    "RadioButton": "Botao de radio",
    "Reschedule.appointment_details": "Detalhes do compromisso",
    "Reschedule.confirm_time": "Confirmar horário",
    "Reschedule.error": "Este compromisso não pode ser reagendado",
    "Reschedule.label": "Confirmação de reagendamento",
    "Reschedule.reschedule": "Reagendar",
    "Security.continue": "Continuar",
    "Security.email_verification": "Verificação de e-mail",
    "Security.please_verify": "Verifique seu endereço de e-mail antes de agendar seu compromisso.",
    "SelectInput.default": "Selecionar",
    "Service.all_categories_title": "Todas as categorias",
    "Service.category_title": "Categorias",
    "Service.five_minutes": "< 5 minutes",
    "Service.hours": "{hours, plural, one {# hora} other {# horas}}",
    "Service.minutes": "{minutes, plural, one {# minuto} other {# minutos}}",
    "Service.mobile_header": "Em qual área você precisa de assistência?",
    "Service.no_services": "Nenhum serviço encontrado",
    "Service.read_less": "Ler menos",
    "Service.read_more": "Ler mais",
    "Service.select_title": "Selecionar um serviço",
    "Service.select_title_prefill": "{name},{break}selecione um serviço",
    "Service.service_count": "{number, plural, one {# serviço} other {# serviços}}",
    "Service.uncategorized_title": "Outro",
    "ServiceHeader.group": "Grupo",
    "ServiceInstructions.title": "Instruções",
    "ShortcutExpired.message": "Este link expirou.",
    "Sidebar.footer": "Com a tecnologia",
    "Sidebar.title": "Agendar compromisso",
    "Sidebar.title_check_in": "Check-in",
    "Sidebar.title_join_the_line": "Junte-se à linha",
    "SpecificUserLanguagePreferences.title": "Preferência de idioma",
    "StepCounter.location": "{location}",
    "StepCounter.location_address": "{address}",
    "StepCounter.location_name": "{name}",
    "StepCounter.title": "Etapa {current} de {end}",
    "Steps.check_in": "Check-in",
    "Steps.check_in_description": "<p> Faça o check-in para o seu compromisso. <\/p> <p> Iremos notificá-lo quando um membro da equipe estiver pronto para atendê-lo. <\/p>",
    "Steps.date_time": "Data e hora",
    "Steps.date_time_no_wait_times": "Assim que um membro da equipe estiver pronto para atendê-lo",
    "Steps.date_time_with_wait_times": "Tempo de espera estimado: {time} minutos",
    "Steps.date_time_with_zero_wait_times": "Sem tempo de espera neste momento",
    "Steps.details": "Detalhes",
    "Steps.details_description": "<p> Adicione-se para ser visto hoje. <\/p> <p> Iremos notificá-lo para voltar quando um membro da equipe estiver pronto para atendê-lo. <\/p>",
    "Steps.location": "Local",
    "Steps.meeting_details": "Detalhes da reunião",
    "Steps.meeting_methods": "Método de reunião",
    "Steps.no_staff_warning": "Ao entrar na fila, você será atendido pelo primeiro funcionário disponível. Este pode não ser {name} , que você selecionou anteriormente",
    "Steps.service": "Serviço",
    "Steps.services": "Serviços",
    "Steps.staff": "Equipe",
    "Steps.staff_preference": "Preferência de equipe",
    "Steps.summary": "Resumo",
    "Steps.your_details": "Seus detalhes",
    "Summary.book_appointment": "Agendar compromisso",
    "Summary.header": "Quase terminando! Revisar e agendar",
    "Summary.join_the_line": "Junte-se à linha",
    "Summary.please_review": "Revise",
    "SummaryExpansionPanel.title": "Resumo",
    "SupportedLanguagePreferencePicker.anyone": "qualquer pessoa",
    "SupportedLanguagePreferencePicker.anyone_available": "qualquer pessoa disponível",
    "SupportedLanguagePreferencePicker.assigned_staff": "sua equipe atribuída",
    "SupportedLanguagePreferencePicker.language_preference": "preferência de idioma",
    "SupportedLanguagePreferencePicker.specific_language": "qualquer pessoa que fale {language}",
    "Svg.alt_text.appointment": "Compromisso",
    "Svg.alt_text.back": "Voltar",
    "Svg.alt_text.back_chevron": "Voltar",
    "Svg.alt_text.checkbox_not_selected": "Caixa de seleção não marcada",
    "Svg.alt_text.checkbox_selected": "Item de caixa de seleção marcado",
    "Svg.alt_text.checkmark": "Marca de seleção",
    "Svg.alt_text.clock": "Relógio",
    "Svg.alt_text.coconut_logo": "Logotipo da Coconut Software",
    "Svg.alt_text.completed": "Concluído",
    "Svg.alt_text.continue": "Continuar",
    "Svg.alt_text.country_selected": "País selecionado",
    "Svg.alt_text.down_chevron": "Mostrar mais",
    "Svg.alt_text.drop_down_arrow": "Seta suspensa",
    "Svg.alt_text.edit": "Editar",
    "Svg.alt_text.edit_timezone": "Alterar fuso horário",
    "Svg.alt_text.expand": "Expandir",
    "Svg.alt_text.expanded": "Expandido",
    "Svg.alt_text.forward_chevron": "Encaminhar",
    "Svg.alt_text.hide_location_details": "Ocultar detalhes do local",
    "Svg.alt_text.in_person": "No local",
    "Svg.alt_text.item_selected": "Item selecionado",
    "Svg.alt_text.language": "Idioma",
    "Svg.alt_text.location_pin": "Local",
    "Svg.alt_text.near_me": "Próximo a mim",
    "Svg.alt_text.next_month": "Próximo mês",
    "Svg.alt_text.next_week": "Próxima semana",
    "Svg.alt_text.person": "Pessoa",
    "Svg.alt_text.phone": "Telefone",
    "Svg.alt_text.previous_month": "Mês anterior",
    "Svg.alt_text.previous_week": "Semana anterior",
    "Svg.alt_text.search": "Pesquisar",
    "Svg.alt_text.select_category": "Selecionar uma categoria",
    "Svg.alt_text.select_language": "Selecionar um idioma",
    "Svg.alt_text.show_location_details": "Mostrar detalhes do local",
    "Svg.alt_text.timezone": "Fuso horário",
    "Svg.alt_text.timezone_selected": "Fuso horário selecionado",
    "Svg.alt_text.up_chevron": "Mostrar menos",
    "Svg.alt_text.upload": "Carregar",
    "Svg.alt_text.user_selected": "Usuário selecionado",
    "Svg.alt_text.video": "Vídeo",
    "Svg.alt_text.walk_in": "Ícone de serviço walk-in",
    "TimeChunk.no_spots": "Não restam vagas",
    "TimeChunk.spots_remaining": "{number, plural, one {# vaga restante} other {# vagas restantes}}",
    "TimeChunk.unlimited_spots": "Vagas ilimitadas",
    "TimeChunks.afternoon": "Tarde",
    "TimeChunks.evening": "Noite",
    "TimeChunks.morning": "Manhã",
    "TimeChunks.no_available_times": "Nenhum horário disponível",
    "TimeChunks.no_available_times_in_month": "Nenhuma disponibilidade encontrada este mês.",
    "TimeChunks.no_available_times_in_range": "Nenhuma disponibilidade encontrada dentro de um período {range} {rangeUnit}.",
    "TimeChunks.no_available_times_in_range_month": "Nenhuma disponibilidade encontrada dentro de um período {range} meses.",
    "TimeChunks.no_available_times_in_range_week": "Nenhuma disponibilidade encontrada dentro de um período {range} semanas.",
    "TimeChunks.no_available_times_in_week": "Nenhuma disponibilidade encontrada esta semana.",
    "TimeChunks.no_available_times_on": "Nenhum horário disponível em {date}",
    "TimeChunks.select_another_day": "Selecione outro dia para encontrar horários disponíveis",
    "TimeChunks.select_another_month": "Tente outro mês.",
    "TimeChunks.select_another_range": "Tente outro membro da equipe ou local.",
    "TimeChunks.select_another_week": "Tente outra semana.",
    "TimeChunks.select_user": "Selecione um membro da equipe para ver a disponibilidade dele",
    "TimezoneSelectInput.label": "Região do fuso horário",
    "TimezoneSelectInput.required": "O fuso horário é obrigatório.",
    "TimezonesShownIn.label": "Mostrando horários em",
    "TooLateToCancelModal.message": "Você não pode mais cancelar este compromisso online.",
    "TooLateToCancelModal.title": "Tarde demais para cancelar",
    "Ui.archive": "Arquivar",
    "Ui.back": "Voltar",
    "Ui.back_to_previous": "Voltar para: {previous}",
    "Ui.cancel": "Cancelar",
    "Ui.clear": "Apagar",
    "Ui.close": "Fechar",
    "Ui.confirm": "Confirmar",
    "Ui.continue": "Continuar",
    "Ui.current_selection": "Seleção atual",
    "Ui.details_about_name": "Detalhes sobre {name}",
    "Ui.details_about_name_and_address": "Detalhes sobre {name}, em {address}",
    "Ui.dismiss": "Descartar",
    "Ui.do_not_continue": "Continuar",
    "Ui.hours_of_operation": "Horarios de funcionamento",
    "Ui.near_me": "Próximo a mim",
    "Ui.next": "Avançar",
    "Ui.ok": "OK",
    "Ui.search": "Pesquisar",
    "Ui.search.instructions": "Por favor, comece a digitar",
    "Ui.search.no_results_found": "Nenhum resultado encontrado",
    "Ui.search.plain": "Procurar",
    "Ui.select": "Selecionar",
    "Ui.select_name": "Selecionar {name}",
    "Ui.selected": "Selecionado",
    "Ui.sign_out": "Sair",
    "Ui.type_here": "Digite aqui...",
    "Ui.undo": "Desfazer",
    "UserPreference.anyone": "Qualquer pessoa",
    "UserPreference.anyone_available": "Qualquer pessoa disponível",
    "UserPreference.assign_me_short_title": "{language}",
    "UserPreference.assign_me_subtitle": "Atribuir-me somente a alguém que fale {language}",
    "UserPreference.assign_me_title": "Qualquer pessoa que fale {language}",
    "UserPreference.assigned_title": "Sua equipe atribuída",
    "UserPreference.header": "Você tem preferência por algum membro da equipe?",
    "UserPreference.language_title": "{language}",
    "UserPreference.no_preference": "Nenhuma preferência",
    "UserPreference.random_subtitle": "Não tenho uma preferência",
    "UserPreference.random_title": "Qualquer pessoa disponível",
    "UserPreference.see_all_staff": "Ver toda a equipe",
    "UserPreference.specific_subtitle": "Sei exatamente com quem quero me reunir",
    "UserPreference.specific_title": "Um membro específico da equipe",
    "Validator.address": "endereço",
    "Validator.cell_phone": "número de celular",
    "Validator.city": "cidade",
    "Validator.country": "país",
    "Validator.email": "e-mail",
    "Validator.first_name": "nome",
    "Validator.home_phone": "telefone residencial",
    "Validator.last_name": "sobrenome",
    "Validator.meeting_method": "método de reunião",
    "Validator.notes": "notas",
    "Validator.phone_code": "Código de país incorreto",
    "Validator.phone_country_code": "Forneça um código de país de 1 a 3 dígitos precedido por '+' e seguido por seu número de telefone.",
    "Validator.phone_length": "Comprimento incorreto do telefone",
    "Validator.phone_valid": "Por favor forneça um número de telefone válido.",
    "Validator.postal_code": "código postal",
    "Validator.province": "província",
    "Validator.state": "estado",
    "Validator.work_phone": "telefone comercial",
    "Validator.zip_code": "cep",
    "ViewOnMapButton.title": "Visualizar no mapa",
    "WaitTime.loading": "Obtendo tempo de espera para walk-in...",
    "WaitTime.no_walkins_currently": "Não atendendo walk-ins no momento",
    "WaitTime.wait": "Tempo de espera atual para walk-in: {wait}",
    "WaitTime.wait_time": "Tempo de espera atual para walk-in: {waitTime}",
    "WeeklyDatePicker.next_week": "Próxima semana",
    "WeeklyDatePicker.previous_week": "Semana anterior",
    "WhatNow.homepage": "Ir para o site da {vendor}",
    "WhatNow.title": "E agora?"
}